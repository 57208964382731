const config = {
  // Github Converted Token from -> https://www.utilities-online.info/base64
  githubConvertedToken:
    "Z2hwX2hjNUxHY2ROMnZOYVFJMFFPazhHM3NFbHkxTmpTcDIyWVFlMw==",

  // Github UserName
  githubUserName: "samiurprapon",
};

export default config;
